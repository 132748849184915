<template>
  <div class="col-md-12 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Usuarios</h1>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12">
              <div class="form-group row">
              <form v-on:submit.prevent="searchItems({ key: keywords})">
                  <div class="input-group">
                    <input type="text" v-model="keywords" class="form-control" placeholder="" aria-label="Buscar por palabras claves" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                      <button type="submit" class="btn btn-outline-primary">Buscar</button>
                      <button
                        type="button"
                        v-on:click="newSearch()"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#searchModal"
                      >Búsqueda avanzada</button>                
                      <searchusuario v-on:search="searchItems" v-bind:item="search"></searchusuario>
                    </div>
                  </div>                
                </form>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="">
              <button v-on:click="$router.push({ name: 'CreateUsuario', params: { add: true, search: search } });" class="btn btn-primary"><i data-feather="plus-circle"></i>Crear Usuario</button>
            </div>
            <br>
            <div v-if="filter" class="alert alert-primary" role="alert">
              {{ keywords }}
              <v-icon v-on:click="closeSearch">mdi-filter-remove</v-icon>
            </div>
            <div class="tab-content" id="nav-tabContent">
              <div class="table-responsive">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <td></td>
                      <td>Usuario</td>
                      <td>Nombre</td>
                      <td>Apellido</td>
                      <td># Identificación</td>
                      <td>Correo electrónico</td>
                      <td>Tipo</td>
                      <td>Fecha registro</td>
                      <td>Última sesión</td>
                      <td>Inactivo</td>
                      <td>Ver todos los documentos</td>
                      <td>Modificar todos los documentos</td>
                      <td>Ver todas las salas</td>
                      <td>Modificar todas las salas</td>
                      <td class="text-center"><input type="checkbox" v-model="allChecked" v-on:click="checkAllItems"></td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(item, index) in items" v-bind:class="{ inactivo: item.usu_inactivo }">
                      <td class="text-center">
                        <button v-on:click="editItem(item._id)" class="btn btn-primary">Editar</button>
                      </td>
                      <td>{{ item.usu_cdgo }}</td>
                      <td>{{ item.usu_nmbre }}</td>
                      <td>{{ item.usu_aplldo }}</td>
                      <td>{{ item.usu_identificacion }}</td>
                      <td>{{ item.usu_email }}</td>
                      <td>{{ item.tpousu_usuarios[0] != undefined ? item.tpousu_usuarios[0].tus_nmbre : '' }}</td>
                      <td>{{ item.usu_fechareg | formatDateTime }}</td>
                      <td>{{ item.log_fecha | formatDateTime }}</td>
                      <td>{{ item.usu_inactivo | formatBoolean }}</td>
                      <td>{{ item.usu_ver_docs | formatBoolean }}</td>
                      <td>{{ item.usu_modificar_docs | formatBoolean }}</td>
                      <td>{{ item.usu_ver_salas | formatBoolean }}</td>
                      <td>{{ item.usu_modificar_salas | formatBoolean }}</td>
                      <td class="text-center">
                        <input type="checkbox" v-bind:value="item._id" v-model="delItems">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <br>
            <!--
            <div>
              <button class="btn btn-danger" v-on:click="deleteItems" v-bind:disabled="!checkedItems">
                <i data-feather="delete"></i>Eliminar
              </button>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { VIcon } from 'vuetify/lib';
import SearchUsuario from "./SearchUsuario.vue";
import { formatBoolean, formatDateTime } from './../../plugins/filters';

export default {
  components: {
    //VIcon,
    searchusuario: SearchUsuario
  },
  data(){
    return{
      items: [],
      delItems: [], //List of items to delete
      allChecked: false, //Check all items
      keywords:'',
      search: {},
      filter: false, // Items are filtered
    }
  },
  filters: {
    formatBoolean, formatDateTime
  },
  computed: {
    checkedItems(){
      if (this.delItems.length > 0){
        return true;
      } else {
        return false;
      }
    }
  },
  mounted: function() {
    feather.replace();
  },
  created: function()
  {
    if (this.$route.params.search) {
      this.search = this.$route.params.search;
      this.searchItems(this.search);
    } else {
      this.searchItems();
    }
  },
  methods: {
    deleteItem(id, index)
    {
      const response = confirm('Está seguro que desea eliminar?');
      if (response) {
        let uri = '/usuarios/delete/'+id;
        this.axios.get(uri)
        .then(res => {
          this.items.splice(index, 1);
        })
        .catch(err => console.log(err));
      }
      return;
    },
    deleteItems()
    {
      if (this.delItems.length > 0){
        const response = confirm('Está seguro que desea eliminar los registros seleccionados?');
        if (response) {
          for (var i = 0; i < this.delItems.length; i++){
            //alert('Item(' + i + '): ' + this.delItems[i]);
            let uri = '/usuarios/delete/' + this.delItems[i];
            this.axios.get(uri)
            .then(res => {
              //this.items.splice(index, 1);
              if (this.search) {
                this.searchItems(this.search);
              }
            })
            .catch(err => {
              console.log(err);
              for (var m = 0; m < this.items.length; m++){
                if (this.delItems[i] == this.items[m]._id){
                  alert('No se ha podido eliminar el item ' + this.items[m]);
                  m = this.items.length;
                }
              }
            });
          }
          if (this.filter){
            this.searchItems(this.search);
          } else {
            this.searchItems();
          }
        }
      }
      return;
    },
    checkAllItems(){
      this.delItems = [];
      if (!this.allChecked){
        for (var i = 0; i < this.items.length; i++){
          this.delItems.push(this.items[i]._id);
        }
      }
    },
    searchItems(p){
      if (p != null){
        if (p.key){
          this.keywords = p.key;
        } else {
          this.keywords = '';
        }
      } else {
        p = {};
      }
      
      this.message = 'Buscando usuarios';
      p.cmp_id = this.$store.state.company;
      let uri = '/usuarios/search';
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.post(uri, p)
      .then(response => {
        console.log(response);
        var list = response.data;

        const last = (obj) => {
          let uri = '/logs/last';
          this.axios.post(uri, { cmp_id: String(obj.cmp_id), usu_id: String(obj._id) })
          .then((response) => {
            if (response.data){
              this.$set(obj, 'log_fecha', response.data.log_fecha);
            }        
          })
          .catch((err) => {

          });
        }

        for(let i = 0; i < list.length; i++){
          last(list[i]);
        }

        this.items = list;
        this.search = p;
        this.delItems = [];
        this.filter = true;
      })
      .catch(err => {
        console.log(err);
        this.message = '¡Error al buscar los usuarios' + err;
      });      
    },
    closeSearch(){
      this.filter = false;
      this.search = {};
      this.keywords = '';
      this.items = [];
      this.searchItems();
    },
    newSearch () {
      this.search = {};
    },
    createItem(){
      this.$router.push({ name: 'CreateUsuario', params: { add: true, search: this.search } });
    },
    editItem(id){
      this.$router.push({ name: 'CreateUsuario', params: { id: id, add: false, search: this.search } });
    }
  }
}
</script>

<style>
.inactivo {
  /*
  background-color: #FFEAE8;
  */
  color: lightcoral;
  text-decoration: line-through;
}
</style>